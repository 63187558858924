<template>
<div style="height:100vh" class="mb-6">
    <div class="div-one"></div>
    <div class="div-two"></div>
    <div class="box is-flex is-align-items-center is-justify-content-center hero-container">
        <h1 class="is-size-5-mobile is-size-3-tablet mr-2" style="width: fit-content">{{$t('about_animation_fixed')}}</h1>
      <div class="text-container" style="width:fit-content">
        <ul style="width:fit-content" class="has-text-centered">
           <li> <p class="is-size-5-mobile is-size-3-tablet">{{$t('about_animation_word_1')}}</p></li>
           <li> <p class="is-size-5-mobile is-size-3-tablet">{{$t('about_animation_word_2')}}</p></li>

        </ul>
      </div>
    </div>
</div>



  <div class="max-width m-auto">
    <div class="columns m-0 is-justify-content-space-between is-align-items-center">
      <div class="column is-12-mobile is-5">
        <div>
        <img src="@/assets/img/about/person.jpg" alt="">
        </div>
      </div>
      <div class="column m-auto is-11-mobile about content" >
        <h2 class="is-size-4 mb-4">{{$t('about_title')}}</h2>
        <underline class="block"/>
        <p class="block">{{$t('about_body_1')}}</p>
        <p class="block">{{$t('about_body_2')}}</p>
        <p class="block">{{$t('about_body_3')}}</p>
        <p class="block">{{$t('about_body_4')}}</p>
        <p class="block">{{$t('about_body_5')}}</p>
        <p class="block">{{$t('about_body_6')}}</p>
        <p class="block">{{$t('about_body_7')}}</p>
        <p class="block">{{$t('about_body_8')}}</p>
        <p class="block">{{$t('about_body_9')}}</p>
      </div>
    </div>
  </div>
</template>

<script>
import data from '../../data/data.json'

export default {
  setup() {
    
  },
  data() {
    return {
      data: data
    }
  },
  mounted() {
  window.scrollTo({
     top: 0,
     left: 0,
   });
  },
}
</script>
<style lang="scss">
/* Code that does the work */

.div-one {
  background:red;
  width: 100vw;
  height: 100vh;
   background: url('~@/assets/img/about/about_2.jpg');
  width: 100vw;
  height: 100vh;
  background-repeat:no-repeat;
  background-size:cover;
  background-position:50%;
}

.div-two {
  -webkit-clip-path: polygon(100vw 0, 0% 100%, 100vw 100vh);
  clip-path: polygon(100vw 0, 0% 100vh, 100vw 100vh);
  background: url('~@/assets/img/about/about_1.jpg');
  width: 100vw;
  height: 100vh;
  background-repeat:no-repeat;
  background-size:cover;
  background-position-y: 40%;
  // background-position:bottom;

}

.div-one, .div-two{
  position: absolute;
}

.overlay {
  width: 100vw;
  height: 100vh;
  background: rgba( 0, 0, 0, 0.3)
}

.box {
  background-color: rgba(0, 0, 0, 0.336);
  position: absolute; 
  top: 35%; 
  left: 50%; 
  transform:translate(-50%, -50%); 
}

.box {
h1 {
  color: var(--primary);
  line-height: 1.5;
  overflow: hidden;
  position: relative;
  width: 550px;
}

}  

.about{
  p{
    font-size: 1.2rem;
  }
}
.text-container {
   height:35px;
   overflow:hidden;
   border-radius:10px;
   width:200px;
}
.text-container ul {
  color:var(--primary);
  padding-left:10px;
  padding-right:10px;
  list-style:none;
  animation: 8s linear 0s normal none infinite change1;
  -webkit-animation: 8s linear 0s normal none infinite change1;
  -moz-animation:8s linear 0s normal none infinite change1;
  -o-animation:8s linear 0s normal none infinite change1;
}

.text-container ul li {
  line-height:35px;
}
.hero-container{
  flex-direction: column;
  @media (min-width:769px) {
    flex-direction: row;
  }
}
@-webkit-keyframes change1 {
  0%   {margin-top:0px;}
  15%  {margin-top:0px;}
  25%  {margin-top:-38px;}
  40%  {margin-top:-38px;}
  // 50%  {margin-top:-70px;}
  // 65%  {margin-top:-70px;}
  // 75%  {margin-top:-38px;}
  // 85%  {margin-top:-38px;}
  100% {margin-top:0;}
}
@keyframes change1 {
  0%   {margin-top:0px;}
  30%  {margin-top:0px;}

  40%  {margin-top:-38px;}
  70%  {margin-top:-38px;}

  80%  {margin-top:-0px;}
  // 80%  {margin-top:-0px;}

  100% {margin-top:0;}
}

</style>